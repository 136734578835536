import request from '@/utils/request'

const exhibition_goodsApi = {
  exhibition_goods_list: '/exhibition_goods/',
  exhibition_goods_create: '/exhibition_goods/',
  exhibition_goods_update: '/exhibition_goods/',
  exhibition_goods_delete: '/exhibition_goods/'
}

/**
 * 列表
 */
export function exhibition_goods_list (parameter) {
  return request({
    url: exhibition_goodsApi.exhibition_goods_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function exhibition_goods_create (parameter) {
  return request({
    url: exhibition_goodsApi.exhibition_goods_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function exhibition_goods_update (parameter, exhibition_goods_id) {
  return request({
    url: exhibition_goodsApi.exhibition_goods_update + exhibition_goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function exhibition_goods_delete (exhibition_goods_id) {
  return request({
    url: exhibition_goodsApi.exhibition_goods_delete + exhibition_goods_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
