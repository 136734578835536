import request from '@/utils/request'

const exhibition_warehouseApi = {
  exhibition_warehouse_list: '/exhibition_warehouse/',
  exhibition_warehouse_create: '/exhibition_warehouse/',
  exhibition_warehouse_update: '/exhibition_warehouse/',
  exhibition_warehouse_delete: '/exhibition_warehouse/'
}

/**
 * 列表
 */
export function exhibition_warehouse_list (parameter) {
  return request({
    url: exhibition_warehouseApi.exhibition_warehouse_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function exhibition_warehouse_create (parameter) {
  return request({
    url: exhibition_warehouseApi.exhibition_warehouse_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function exhibition_warehouse_update (parameter, exhibition_warehouse_id) {
  return request({
    url: exhibition_warehouseApi.exhibition_warehouse_update + exhibition_warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function exhibition_warehouse_delete (exhibition_warehouse_id) {
  return request({
    url: exhibition_warehouseApi.exhibition_warehouse_delete + exhibition_warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
