import request from '@/utils/request'

const bonded_commodityApi = {
  bonded_commodity_list: '/bonded_commodity/',
  bonded_commodity_create: '/bonded_commodity/',
  bonded_commodity_partial_update: '/bonded_commodity/',
  bonded_commodity_delete: '/bonded_commodity/',
  bonded_commodity_purchase_list: '/bonded_commodity/purchase/',
  bonded_commodity_purchase_create: '/bonded_commodity/purchase/',
  bonded_commodity_purchase_update: '/bonded_commodity/purchase/',
  bonded_commodity_purchase_delete: '/bonded_commodity/purchase/',
  bonded_commodity_record_list: '/bonded_commodity/record/',
  bonded_commodity_out_warehouse_list: '/bonded_commodity/out/warehouse/',
  bonded_commodity_out_warehouse_create: '/bonded_commodity/out/warehouse/',
  bonded_commodity_warehouse_excel: '/bonded_warehouse/bonded_commodity/excel/'
}

/**
 * 列表
 */
export function bonded_commodity_out_warehouse_list (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_out_warehouse_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_commodity_out_warehouse_create (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_out_warehouse_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 列表
 */
export function bonded_commodity_record_list (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function bonded_commodity_list (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_commodity_create (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bonded_commodity_partial_update (parameter, bonded_commodity_id) {
  return request({
    url: bonded_commodityApi.bonded_commodity_partial_update + bonded_commodity_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_commodity_delete (bonded_commodity_id) {
  return request({
    url: bonded_commodityApi.bonded_commodity_delete + bonded_commodity_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function bonded_commodity_purchase_list (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_purchase_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 修改
 */
export function bonded_commodity_purchase_update (parameter, bonded_commodity_id) {
  return request({
    url: bonded_commodityApi.bonded_commodity_purchase_update + bonded_commodity_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_commodity_purchase_delete (bonded_commodity_id) {
  return request({
    url: bonded_commodityApi.bonded_commodity_purchase_delete + bonded_commodity_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 添加
 */
export function bonded_commodity_purchase_create (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_purchase_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 导出库存
 */
export function bonded_commodity_warehouse_excel (parameter) {
  return request({
    url: bonded_commodityApi.bonded_commodity_warehouse_excel,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    responseType: 'blob',
    data: parameter
  })
}
